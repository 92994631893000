<template>
  <div>
    <Galery></Galery>
  </div>
</template>


<script>
import Galery from '@/components/galery/index.vue';

export default {
  name: 'GaleryPage',
  components: {
    Galery,
  }

}
</script>

<style scoped>

</style>
