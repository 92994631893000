<template>
  <div>
    <About></About>
    
  </div>
</template>

<script>
import About from '@/components/about/index.vue';


export default {
  name: 'AboutPage',
  components: {
    About,
  }
}
</script>
<style>

</style>
