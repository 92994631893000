<template>
  <div>
    <contact></contact>
  </div>
</template>


<script>
import Contact from '@/components/contact/index.vue';


export default {
  name: 'ContactPage',
  components: {
    Contact,
  }
}
</script>
<style>

</style>